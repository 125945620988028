import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { Box, CircularProgress, ClickAwayListener, InputAdornment, InputBase, Typography, } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { escapeStringRegexp, searchDelay } from 'globals/global.functions';
import { SEARCH_MINIMUM_CHAR_COUNT } from 'globals/global.constants';
import { useSearchBarStyles } from './SearchBar.styles';
import clsx from 'clsx';
import { get } from 'lodash';
import { useAppSelector } from 'hooks/reduxHooks';
import { API_LOAD_STATE } from 'globals/enums';
/**
 * @description to bold selected string of any particular string
 * @param param
 * @returns
 */
var BoldString = function (_a) {
    var text = _a.text, shouldBeBold = _a.shouldBeBold;
    var escapedString = escapeStringRegexp(shouldBeBold);
    var textArray = text.split(new RegExp(escapedString, 'i'));
    var searchedTextIndex = text.search(new RegExp(escapedString, 'i'));
    var searchedText = text.slice(searchedTextIndex, searchedTextIndex + shouldBeBold.length);
    return (_jsx("span", { children: textArray.map(function (item, index) { return (_jsxs(_Fragment, { children: [item, index !== textArray.length - 1 && _jsx("b", { children: searchedText })] })); }) }));
};
var SearchBarField = withStyles(InputBase, {
    root: {
        border: '1px solid #707070',
        overflow: 'hidden',
        borderRadius: 8,
        backgroundColor: '#ffff',
        minWidth: '270px',
        height: '35px',
        padding: '0px 0px 0px 15px',
    },
});
/**
 * @description Search bar component to provide search input field
 * @function SearchBar
 * @return {JSX}
 */
var SearchBar = function (_a) {
    var _b, _c;
    var handleSearchChange = _a.handleSearchChange, lastEvaluatedKey = _a.lastEvaluatedKey, tableData = _a.tableData, isNewDesign = _a.isNewDesign, placeholder = _a.placeholder, width = _a.width, value = _a.value, isError = _a.isError, errorText = _a.errorText, disabled = _a.disabled, searchList = _a.searchList, searchLoading = _a.searchLoading, minHeight = _a.minHeight, maxLength = _a.maxLength, _d = _a.searchListWidth, searchListWidth = _d === void 0 ? '30.5%' : _d, _e = _a.isSelected, isSelected = _e === void 0 ? false : _e, _f = _a.errorMessage, errorMessage = _f === void 0 ? '' : _f, loading = _a.loading, propInputRef = _a.inputRef, // Renaming prop for clarity
    _g = _a.onSearchTextChange, // Renaming prop for clarity
    onSearchTextChange = _g === void 0 ? function (searchQuery) { } : _g, _h = _a.noRightMargin, noRightMargin = _h === void 0 ? false : _h;
    var _j = React.useState(''), searchQuery = _j[0], setSearchQuery = _j[1];
    // isOpen state is used to show and hide dropDown list of matched value
    var _k = React.useState(false), isOpen = _k[0], setIsopen = _k[1];
    var classes = useSearchBarStyles().classes;
    var internalInputRef = useRef(); // Declare internal reference
    var inputRef = propInputRef || internalInputRef; // Use propInputRef if available, otherwise fallback
    var isDataLoading = useAppSelector(function (state) { return get(state, loading || ''); });
    React.useMemo(function () {
        // set value into searchQuery only if searchQuery have some value
        if (searchQuery) {
            setSearchQuery(value);
        }
        if (!value) {
            setIsopen(false);
        }
    }, [value]);
    React.useEffect(function () {
        onSearchTextChange(searchQuery);
    }, [searchQuery]);
    React.useEffect(function () {
        if (isDataLoading === API_LOAD_STATE.SUCCESSFUL) {
            inputRef.current.focus();
        }
    }, [isDataLoading, inputRef.current]);
    var searchHandler = function (inputValue, lastEvaluatedKey, tableData) {
        if (inputValue && (inputValue === null || inputValue === void 0 ? void 0 : inputValue.length) > SEARCH_MINIMUM_CHAR_COUNT) {
            setIsopen(true);
            handleSearchChange(inputValue, lastEvaluatedKey, tableData);
        }
        else if (!inputValue) {
            setIsopen(false);
            handleSearchChange(lastEvaluatedKey ? null : inputValue, lastEvaluatedKey, tableData);
        }
    };
    var optimizedFn = React.useCallback(searchDelay(searchHandler), []);
    function handleChange(e) {
        var inputValue = e.target.value;
        setSearchQuery(inputValue);
        optimizedFn(inputValue, lastEvaluatedKey, tableData);
    }
    var crossHandler = function () {
        setSearchQuery('');
        searchHandler(lastEvaluatedKey ? null : '', '', []);
        inputRef.current.focus(); // Keep focus in the input field
    };
    if (isNewDesign) {
        return (_jsx(_Fragment, { children: _jsxs(Box, { style: { width: width, maxWidth: 452 }, children: [_jsxs(Box, { "data-testid": 'SearchBar', component: 'form', onSubmit: function (e) {
                            e.preventDefault();
                        }, children: [_jsx(InputBase, { sx: { border: isError ? '1px solid #cc0000' : 0 }, inputRef: inputRef, className: classes.root, autoComplete: 'off', value: searchQuery, onChange: handleChange, disabled: disabled, placeholder: placeholder ? placeholder : 'Search by name', inputProps: { maxLength: maxLength }, endAdornment: searchQuery ? (!isSelected && (_jsx(InputAdornment, { onClick: crossHandler, position: 'start', style: { margin: '5px', cursor: 'pointer' }, children: _jsx(CloseIcon, {}) }))) : (_jsx(InputAdornment, { position: 'start', style: { margin: '5px', color: '#00a9cf' }, children: _jsx(SearchIcon, {}) })) }), isError && (_jsx(Typography, { color: '#cc0000', textAlign: 'right', fontSize: 14, children: errorText }))] }), _jsx(Box, { children: searchList && isOpen && (_jsx(ClickAwayListener, { onClickAway: function () { return setIsopen(false); }, children: _jsxs(Box, { className: classes.searchList, width: searchListWidth, minHeight: minHeight, children: [searchLoading && (_jsx(Box, { display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px 0', children: _jsx(CircularProgress, {}) })), !searchLoading &&
                                        (searchList === null || searchList === void 0 ? void 0 : searchList.length) === 0 &&
                                        !errorMessage && (_jsx(Typography, { className: classes.searchListItems, sx: { cursor: 'auto' }, children: "No Match Found" })), !searchLoading && errorMessage && (_jsx(Typography, { className: classes.searchListItems, sx: { cursor: 'auto', color: '#cc0000', fontSize: 15 }, children: errorMessage })), !searchLoading &&
                                        (searchList === null || searchList === void 0 ? void 0 : searchList.length) > 0 &&
                                        !errorMessage &&
                                        (searchList === null || searchList === void 0 ? void 0 : searchList.map(function (item) { return (_jsx(Typography, { className: classes.searchListItems, sx: { cursor: 'pointer' }, onClick: function () {
                                                setSearchQuery(item);
                                                setIsopen(false);
                                            }, children: _jsx(BoldString, { text: item, shouldBeBold: searchQuery }) }, item)); }))] }) })) })] }) }));
    }
    return (_jsx(Box, { "data-testid": 'SearchBar', component: 'form', className: 'search-bar', onSubmit: function (e) {
            e.preventDefault();
        }, style: {
        // margin: '0px 24px',
        // marginBottom: 10,
        // marginRight: noRightMargin ? '0px' : '24px',
        }, children: _jsx(SearchBarField, { autoComplete: 'off', inputRef: inputRef, value: searchQuery, onChange: handleChange, placeholder: 'Search by name', disabled: disabled, endAdornment: searchQuery ? (_jsx(InputAdornment, { onClick: crossHandler, position: 'start', className: clsx((_b = {},
                    _b[classes.crossIcon] = true,
                    _b[classes.disableIcon] = disabled,
                    _b)), children: _jsx(CloseIcon, {}) })) : (_jsx(InputAdornment, { position: 'start', className: clsx((_c = {},
                    _c[classes.searchIcon] = true,
                    _c[classes.disableIcon] = disabled,
                    _c)), children: _jsx(SearchIcon, {}) })) }) }));
};
export default SearchBar;
